.svg-1685628507-599224 {
    shape-rendering: geometricPrecision;
    text-rendering: geometricPrecision;
    image-rendering: optimizeQuality;
    fill-rule: evenodd;
    clip-rule: evenodd;
}

.opacity-0-963 {
    opacity: 0.963
}

.opacity-0-968 {
    opacity: 0.968
}

.opacity-0-016 {
    opacity: 0.016
}

.opacity-0-96 {
    opacity: 0.96
}

.opacity-0-949 {
    opacity: 0.949
}

.opacity-0-957 {
    opacity: 0.957
}

.opacity-0-961 {
    opacity: 0.961
}

.opacity-0-964 {
    opacity: 0.964
}

.opacity-0-965 {
    opacity: 0.965
}

.opacity-0-967 {
    opacity: 0.967
}

.opacity-0-953 {
    opacity: 0.953
}

.opacity-0-959 {
    opacity: 0.959
}

.opacity-0-95 {
    opacity: 0.95
}

.opacity-0-963 {
    opacity: 0.963
}

.opacity-0-961 {
    opacity: 0.961
}

.opacity-0-96 {
    opacity: 0.96
}

.opacity-0-964 {
    opacity: 0.964
}

.opacity-0-957 {
    opacity: 0.957
}

.opacity-0-951 {
    opacity: 0.951
}

.opacity-0-955 {
    opacity: 0.955
}

.opacity-0-956 {
    opacity: 0.956
}

.opacity-0-952 {
    opacity: 0.952
}

.opacity-0-955 {
    opacity: 0.955
}

.opacity-0-957 {
    opacity: 0.957
}

.opacity-0-957 {
    opacity: 0.957
}

.opacity-0-952 {
    opacity: 0.952
}

.opacity-0-959 {
    opacity: 0.959
}