.bottom-footer {
    color: #a5a6a5;
    width: 900px;
    margin-left: auto;
    margin-right: auto;
}

#footer {
    position: relative;
    bottom: 0;
}